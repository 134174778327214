<template>
    <div class="container">
        <div class="info-container">
            <div class="info-title">
                <span>{{planDetail.name}}</span>
            </div>
            <div class="info-sub">
                <div class="info-sub-item">
                    <span class="key">创建者: </span>
                    <span class="value">{{ designer }}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">创建时间: </span>
                    <span class="value">{{ planDetail.create_time | timeFilter }}</span>
                </div>
                <div class="info-sub-item"></div>
            </div>
        </div>
        <div class="tabs">
            <el-tabs class="my-tabs" v-model="activeTab">
                <el-tab-pane label="周计划" name="1">
                    <schedule-list :plan-type='planType' v-if="activeTab === '1'" :year='planDetail.year'/>
                </el-tab-pane>
                <el-tab-pane label="月评模板" name="2">
                    <evaluation-list :plan-type='planType'  v-if="activeTab === '2'" :year='planDetail.year'/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
import { timeWithWeek } from '@/core/utils';

export default {
    components: {
        ScheduleList:() => import('./components/ScheduleList'),
        EvaluationList:() => import('./components/EvaluationList')
    },
    data() {
        return {
            planId: 0,
            planType: 0,
            activeTab: '1',
            planDetail: {},
            userType: Core.Data.getUserType(),
            providerName: Core.Data.getProvider(),
        };
    },
    created() {
        this.planId = this.$route.query.plan_id;
        this.planType = this.$route.query.plan_type;
        this.getPlanDetail();
    },
    methods: {
        getPlanDetail() {
            Core.Api.Plan.detail(this.planId).then((res) => {
                console.log('getPlanDetail res:', res)
                this.planDetail = res.detail
            });
        },
    },
    computed: {
        createTime() {
            return timeWithWeek(this.planDetail.create_time)
        },
        designer() {
            return Core.Data.getProvider() || (this.planDetail.creator ? this.planDetail.creator.name : '')
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    .info-container {
        width: 100%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 0 30px;
        .info-title {
            padding-top: 20px;
            font-size:20px;
            font-weight:500;
            color:$color-black;
            line-height:28px;
        }
        .info-sub {
            @include flex(row, space-between, center);
            flex-wrap: wrap;
            padding-top: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;
            &-item{
                width: 250px;
                span{
                    font-size: $font-size-base;
                    font-weight: 400;
                    line-height: 20px;
                }
                .key {
                    color:$color-black-light;
                }
                .value {
                    color:$color-black;
                }
            }
        }
    }
    .tabs {
        width: 100%;
        box-sizing: border-box;
    }
}
</style>